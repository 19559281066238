import { useRef, useState } from 'react';
import { useOptimisticValue } from '@/hooks/useOptimisticValue';
import { useToast } from '@/hooks/useToast';
import { useSchedulePermissions } from '@/pages/Schedule/hooks';
import { Button, Heading, useOutsideClick } from '@/ui';
import { useSnippet } from '../../hooks/useSnippet';
import { useUpdateSnippet } from '../../hooks/useUpdateSnippet';
import { AutoResizingTextInput } from './AutoResizingTextInput';

export const SnippetName = () => {
  const [isEditing, setEditing] = useState(false);
  const { error: errorToast } = useToast();
  const inputRef = useRef<HTMLInputElement>(null);
  const { updateSnippet } = useUpdateSnippet();
  const { hasSchedulePermission } = useSchedulePermissions();

  const { data: snippet } = useSnippet();
  const [localName, setLocalName] = useOptimisticValue(snippet?.name ?? '');

  const handleSubmit = () => {
    setEditing(false);

    if (snippet && localName && localName !== snippet?.name) {
      updateSnippet(
        { ...snippet, name: localName },
        {
          onError: () => errorToast('Error renaming snippet'),
        }
      );
    }
  };

  useOutsideClick({
    ref: inputRef,
    handler: handleSubmit,
  });

  if (isEditing) {
    return (
      <AutoResizingTextInput
        _focus={{ borderWidth: 4, boxShadow: 'none' }}
        autoFocus
        focusBorderColor="brand.500"
        fontSize={{ base: '32px', md: '60px' }}
        fontWeight="medium"
        h={{ base: '56px', md: '96px' }}
        margin="auto"
        px="4"
        ref={inputRef}
        textAlign="center"
        transition="none"
        value={localName}
        onChange={(event) => setLocalName(event.currentTarget.value)}
        onFocus={(event) => event.target.select()}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleSubmit();
          }
        }}
      />
    );
  }

  if (!hasSchedulePermission('SNIPPET_EDIT')) {
    return (
      <Heading fontSize={{ base: '32px', md: '60px' }} m="auto">
        {localName}
      </Heading>
    );
  }

  return (
    <Button
      _active={{ bg: 'white', cursor: 'text', borderColor: 'gray.200' }}
      _hover={{ bg: 'white', cursor: 'text', borderColor: 'gray.200' }}
      borderColor="transparent"
      borderRadius="md"
      borderWidth={4}
      color="customgray.dark"
      fontSize={{ base: '32px', md: '60px' }}
      fontWeight="medium"
      m="auto"
      minH={{ base: '56px', md: '96px' }}
      px="4"
      size={{ base: '2xl', md: '3xl' }}
      textAlign="center"
      variant="ghost"
      whiteSpace="normal"
      onClick={() => setEditing(true)}
    >
      {localName}
    </Button>
  );
};
