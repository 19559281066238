import { useOptimisticValue } from '@/hooks/useOptimisticValue';
import { useToast } from '@/hooks/useToast';
import { Visibility } from '@/types/gql.generated';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  Flex,
  Switch,
  type ButtonProps,
  Button,
  InputGroup,
  Input,
  InputRightElement,
} from '@/ui';
import { useSnippet } from '../../hooks/useSnippet';
import { ShareButton } from './components/ShareButton';
import { useUpdateSnippetVisiblity } from './hooks/useUpdateSnippetVisibility';

export const ShareModal = (buttonProps: ButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: snippet } = useSnippet();
  const { updateVisibility } = useUpdateSnippetVisiblity();
  const toast = useToast();
  const url = window.location.toString();

  // Doing optimistic update here in addition to the query level because it can
  // take snippets a while to re-render when there are a lot of entries
  const [optimisticVisibility, setOptimisticVisibility] = useOptimisticValue(
    snippet?.visibility
  );

  const handleCopyClick = () => {
    navigator.clipboard.writeText(url);
    toast.notify('✨ Snippet link copied!');
  };

  return (
    <>
      <ShareButton onClick={onOpen} {...buttonProps} />

      <Modal
        isCentered
        isOpen={isOpen}
        returnFocusOnClose={false}
        variant="primary"
        onClose={onClose}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Share snippet</ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <Text color="customgray.mid" fontSize="md">
              Only the content of the individual snippet will be shared with
              viewers, letting you curate and share portions of your schedule.
            </Text>
            <Flex
              align="center"
              gap="8"
              justify="space-between"
              mt="8"
              w="100%"
            >
              Anyone with the link can view this snippet
              <Switch
                isChecked={optimisticVisibility === Visibility.Public}
                size="lg"
                onChange={({ currentTarget }) => {
                  const visibility = currentTarget.checked
                    ? Visibility.Public
                    : Visibility.Private;
                  setOptimisticVisibility(visibility);
                  updateVisibility(visibility);
                }}
              />
            </Flex>

            {optimisticVisibility === Visibility.Public && (
              <InputGroup mt="3">
                <Input
                  bg="customgray.lightest"
                  border="1px solid rgba(0, 0, 0, 0.1)"
                  borderRadius="6"
                  color="customgray.mid"
                  pr="5rem"
                  readOnly
                  type="text"
                  value={url}
                />
                <InputRightElement mr="1" w="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    variant="link"
                    onClick={handleCopyClick}
                  >
                    copy link
                  </Button>
                </InputRightElement>
              </InputGroup>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
