import { Navigate, useNavigate } from 'react-router-dom';
import useEvent from 'react-use-event-hook';
import { usePageTitle } from '@/hooks/usePageTitle';
import { useToast } from '@/hooks/useToast';
import { useTrackPageView } from '@/hooks/useTrackPageView';
import { queryClient } from '@/lib';
import {
  ConfirmDialog,
  ToggleHiddenInstancesButton,
  EntryModal,
} from '@/pages/Schedule/components';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import {
  useSchedulePermissions,
  useToggleHiddenInstances,
} from '@/pages/Schedule/hooks';
import { useDraftEntryStore } from '@/pages/Schedule/stores';
import type { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';
import { Flex, Spinner, useDisclosure } from '@/ui';
import { createSnippetQueryKey } from '@/utils/queryKeys';
import { BackButton } from './components/BackButton';
import { EditSnippetModal } from './components/EditSnippetModal';
import { Formula } from './components/Formula';
import { MoreMenu } from './components/MoreMenu';
import { ShareModal } from './components/ShareModal';
import { SnippetEntries } from './components/SnippetEntries';
import { SnippetName } from './components/SnippetName';
import { useDeleteSnippet } from './hooks/useDeleteSnippet';
import { useSnippet } from './hooks/useSnippet';
import { useToggleHide } from './hooks/useToggleHide';

export const AuthenticatedScheduleSnippet = () => {
  useTrackPageView('schedule:snippet');
  const { data: snippet, isFetching } = useSnippet();
  const { error: errorToast } = useToast();
  const navigate = useNavigate();
  const { hasSchedulePermission } = useSchedulePermissions();
  const { toggleHide } = useToggleHide();
  const { deleteSnippet } = useDeleteSnippet();
  const { scheduleId } = useScheduleContext();
  const { isShowingHiddenInstances } = useToggleHiddenInstances();
  const editDraftEntry = useDraftEntryStore((state) => state.editDraftEntry);
  usePageTitle(snippet?.name);

  const {
    isOpen: isConfirmDeleteOpen,
    onOpen: openConfirmDelete,
    onClose: closeConfirmDelete,
  } = useDisclosure();
  const {
    isOpen: isEditModalOpen,
    onOpen: openEditModal,
    onClose: closeEditModal,
  } = useDisclosure();

  const hasHiddenEntries = snippet?.entries?.some(
    (entry) => entry.hidden.length > 0
  );

  const invalidateSnippets = useEvent(() => {
    if (snippet?.id) {
      queryClient.invalidateQueries({
        queryKey: createSnippetQueryKey(snippet.id),
      });
    }
  });

  const handleEntryClick = useEvent(
    (entry: DecoratedEntry, instance: DecoratedInstance) => {
      editDraftEntry(entry, instance);
    }
  );

  const handleToggleHideEntryClick = useEvent((entry, instance) => {
    toggleHide(entry, instance);
  });

  const canEditSnippet = hasSchedulePermission('SNIPPET_EDIT');
  const canViewSnippet = hasSchedulePermission('SNIPPET_VIEW');

  if (!canViewSnippet) {
    return <Navigate to="/" />;
  }

  if (!snippet) {
    // This should never be reached because the loader won't render this page
    // unless a snippet exists. Handling here though for type narrowing
    return <div>Snippet not found</div>;
  }

  return (
    <>
      <EntryModal
        onEntryDeleteSuccess={invalidateSnippets}
        onEntryUpdateSuccess={invalidateSnippets}
        onInstanceDeleteSuccess={invalidateSnippets}
      />

      <ConfirmDialog
        confirmButtonText="Yes, delete"
        isOpen={isConfirmDeleteOpen}
        title={`Delete ${snippet.name}?`}
        onCancel={closeConfirmDelete}
        onConfirm={() => {
          deleteSnippet(snippet.id, {
            onError: () => errorToast('Error deleting snippet'),
            onSuccess: () =>
              navigate(`/schedule/${scheduleId}/snippets`, { replace: true }),
          });
        }}
      />

      {isEditModalOpen && <EditSnippetModal isOpen onClose={closeEditModal} />}

      <Flex direction="column" flex="1">
        <Flex
          justify="space-between"
          sx={{ '@media print': { display: 'none' } }}
        >
          <BackButton
            onClick={() => navigate(`/schedule/${snippet.scheduleId}/snippets`)}
          >
            All snippets
          </BackButton>

          <Flex align="center" gap="3">
            {isFetching && <Spinner size="sm" />}
            {canEditSnippet && (
              <>
                {hasHiddenEntries && <ToggleHiddenInstancesButton />}
                <ShareModal />
                <MoreMenu onDelete={openConfirmDelete} onEdit={openEditModal} />
              </>
            )}
          </Flex>
        </Flex>

        <Flex direction="column" flex="1" px={{ base: 0, xl: 100 }}>
          <Flex align="center" mt="4">
            <SnippetName />
          </Flex>

          <Formula mb={{ base: 8, md: 16 }} mt="4" />

          <SnippetEntries
            showHiddenEntries={isShowingHiddenInstances}
            onEntryClick={handleEntryClick}
            onToggleHideEntry={
              canEditSnippet ? handleToggleHideEntryClick : undefined
            }
          />
        </Flex>
      </Flex>
    </>
  );
};
