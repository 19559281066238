import cloneDeep from 'lodash/cloneDeep';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SlChemistry } from 'react-icons/sl';
import { useToast } from '@/hooks/useToast';
import { LabelList } from '@/pages/Schedule/components';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import {
  Box,
  Button,
  CloseButton,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@/ui';
import { useSnippet } from '../../hooks/useSnippet';
import { useUpdateSnippet } from '../../hooks/useUpdateSnippet';
import type { DecoratedSnippet } from '../../types';
import { CategoryButton } from './components/CategoryButton';
import { ColumnHeader } from './components/ColumnHeader';
import { FeedButton } from './components/FeedButton';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const EditSnippetModal = ({ onClose, isOpen }: Props) => {
  const { schedule } = useScheduleContext();
  const { updateSnippet, isPending } = useUpdateSnippet();
  const { notify: notifyToast, error: errorToast } = useToast();
  const { t } = useTranslation('editSnippetModal');
  const [isDirty, setDirty] = useState(false);
  const { data: snippet } = useSnippet();

  const [draftSnippet, setDraftSnippet] = useState(() => cloneDeep(snippet));

  if (!draftSnippet) {
    return null;
  }
  if (!schedule) {
    return null;
  }

  const handleCategoryClick = (
    category: DecoratedSnippet['categories'][number]
  ) => {
    setDirty(true);
    const exists = draftSnippet.categories.find(({ id }) => id === category.id);
    const categories = exists
      ? draftSnippet.categories.filter(({ id }) => id !== category.id)
      : [...draftSnippet.categories, category];
    setDraftSnippet({ ...draftSnippet, categories });
  };

  const handleWhoLabelClick = (
    label: DecoratedSnippet['whoLabels'][number]
  ) => {
    setDirty(true);
    const exists = draftSnippet.whoLabels.find(({ id }) => id === label.id);
    const whoLabels = exists
      ? draftSnippet.whoLabels.filter(({ id }) => id !== label.id)
      : [...draftSnippet.whoLabels, label];
    setDraftSnippet({ ...draftSnippet, whoLabels });
  };

  const handleLabelClick = (label: DecoratedSnippet['labels'][number]) => {
    setDirty(true);
    const exists = draftSnippet.labels.find(({ id }) => id === label.id);
    const labels = exists
      ? draftSnippet.labels.filter(({ id }) => id !== label.id)
      : [...draftSnippet.labels, label];
    setDraftSnippet({ ...draftSnippet, labels });
  };

  const handleFeedClick = (feed: DecoratedSnippet['feeds'][number]) => {
    setDirty(true);
    const exists = draftSnippet.feeds.find(({ id }) => id === feed.id);
    const feeds = exists
      ? draftSnippet.feeds.filter(({ id }) => id !== feed.id)
      : [...draftSnippet.feeds, feed];
    setDraftSnippet({ ...draftSnippet, feeds });
  };

  const handleSubmit = () => {
    updateSnippet(draftSnippet, {
      onError: () => errorToast(t('error_toast')),
      onSuccess: () => {
        notifyToast(t('success_toast'));
        onClose();
      },
    });
  };

  const hasAnyFilters =
    draftSnippet.categories.length > 0 ||
    draftSnippet.whoLabels.length > 0 ||
    draftSnippet.labels.length > 0 ||
    draftSnippet.feeds.length > 0;

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      size="4xl"
      variant="primary"
      onClose={onClose}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          <Flex align="center" flex="1" gap="2">
            <SlChemistry size="28px" style={{ marginTop: -4 }} />
            {t('title')}
          </Flex>
          <Button
            isDisabled={!hasAnyFilters || !isDirty}
            isLoading={isPending}
            mr="2"
            variant="secondary"
            onClick={handleSubmit}
          >
            {t('update')}
          </Button>
          <CloseButton onClick={onClose} />
        </ModalHeader>

        <ModalBody pb="8" px="8" py="3">
          <Flex align="start" gap="6" pt="2">
            <Box flex="1" minW="0">
              <ColumnHeader>{t('categories')}</ColumnHeader>
              <Flex align="flex-start" direction="column" gap={2}>
                {schedule.categories.map((category) => {
                  const isSelected = !!draftSnippet.categories.find(
                    ({ id }) => id === category.id
                  );
                  return (
                    <CategoryButton
                      category={category}
                      isSelected={isSelected}
                      key={category.id}
                      onClick={handleCategoryClick}
                    />
                  );
                })}
              </Flex>
            </Box>

            <Box flex="1" minW="0">
              <ColumnHeader>{t('who')}</ColumnHeader>
              <LabelList
                labels={schedule.whoLabels}
                selected={draftSnippet.whoLabels}
                onClick={handleWhoLabelClick}
              />
            </Box>

            <Box flex="1" minW="0">
              <ColumnHeader>{t('labels')}</ColumnHeader>
              <LabelList
                labels={schedule.labels}
                selected={draftSnippet.labels}
                onClick={handleLabelClick}
              />
            </Box>

            <Box flex="1" minW="0">
              <ColumnHeader>{t('feeds')}</ColumnHeader>
              <Flex direction="column" gap="2">
                {schedule.feeds.map((feed) => {
                  const isSelected = !!draftSnippet.feeds.find(
                    ({ id }) => id === feed.id
                  );
                  return (
                    <FeedButton
                      isSelected={isSelected}
                      key={feed.id}
                      onClick={() => handleFeedClick(feed)}
                    >
                      {feed.title}
                    </FeedButton>
                  );
                })}
              </Flex>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
